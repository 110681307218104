const getButton = `
fragment getButton on LayoutButton {
  type
  css
  name
  iconsrc
  event
  link {
    target
    url
    param {
      key
      value
    }
  }
  action {
    name
    value{
      key
      value
    }
    param {
      key
      value
    }
  }
}
`;

const getComboBox = `
fragment getComboBox on LayoutComboBox {
  type
  css
  element {
    name
    action{
      name
      value{
        key
        value
      }
      param{
        key
        value
      }
    }
  }
}
`;

const getElemtn = `
fragment getElemtn on LayoutElement {
  ... on LayoutComboBox {
    ...getComboBox
  }
  ... on LayoutButton {
    ...getButton
  }
}
`;

const defaultLayoutFragment = `
  ${getElemtn}

  ${getButton}

  ${getComboBox}
`;

const defaultLayoutReturn = `
  display{
    field
    name
    position
    type
  }
  sort {
    name
    order
    field
    default
  }
  batch {
    ...getElemtn
  }
  list {
    values {
      ref {
        key
        value
      }
    }
  }
  options {
    max
    pagesize
    showpage
  }
  info {
    total
    count
    limit
    pageNo
    totalPage
    start    
    hyftdToken
    filterPid
    userCollectionList
    pages {
      val
      active
    }
    showNext
    showPrevious
  }
`;

export default {
  getButton,
  getComboBox,
  getElemtn,
  defaultLayoutReturn,
  defaultLayoutFragment,
};
