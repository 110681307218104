import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import JournalResourceListComp from "components/resource/JournalResourceListComp";
import ResourceListTab from "components/resource/ResourceListTab";
import { autobind } from "react-decoration";
import { page } from "components/page";
import navigate from "lib/navigate";
import qs from "query-string";
import ExhibitionComp from "components/resource/ExhibitionComp";

@withI18next(["common"])
@page
class ExhibitionResourceList extends React.Component {
  constructor(props) {
    super(props);
    let state = this.getQueryState(props);
    this.state = state;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props !== props) {
      if (this.state.auth !== props.readerStore.auth) {
        let { location } = this.props;
        if (location.pathname === "/exhibitionResourceList") {
          this.resetPage();
        }
      }
      this.setQueryState(props);
    }
  }

  @autobind
  setQueryState(props) {
    let state = this.getQueryState(props);

    if (JSON.stringify(state) !== JSON.stringify(this.state)) {
      this.setState({
        ...state,
      });
    }
  }

  @autobind
  getQueryState(props) {
    let { search, readerStore, appStore } = props;
    let { resourceConfig } = appStore;
    let params = { ...search };
    let {
      resourceType,
      pageNo,
      limit,
      searchInput,
      searchField,
      op,
      showType,
      filterField,
      filterValue,
      sort,
      order,
      subject,
      college,
      unit,
      classSn,
      par,
      phonetic,
      strokes,
      language,
      alphabet,
      dbID,
      searchTarget,
      sc,
      domain,
      exhibitionID,
    } = params;
    let pageType = "search";
    let publicationType = "";
    let defaultResourceType =
      resourceConfig && resourceConfig.resourceTabList
        ? resourceConfig.resourceTabList[0]
        : "ebook";

    let resourceTypeSet = ["journal", "ejournal", "ebook", "paper_journal"];

    if (resourceTypeSet.indexOf(resourceType) === -1) {
      resourceType = defaultResourceType;
    }

    if (sort === undefined || sort === "") {
      if (pageType === "hot") {
        sort = "hot";
      }
    }

    if (pageType === undefined || pageType === "") {
      pageType = "list";
    }

    pageNo = pageNo || 1;
    limit = limit || 30;
    searchInput = [].concat(searchInput || []);
    searchField = [].concat(searchField || []);
    op = [].concat(op || []);
    showType = showType || "simple";
    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);

    op.splice(0, 0, "");
    let removeId = 0;
    searchInput = searchInput.filter((v, k) => {
      if (v !== null && v.trim() !== "") {
        return 1;
      } else {
        searchField.splice(k - removeId, 1);
        op.splice(k - removeId, 1);
        removeId++;
      }
      return "";
    });
    op.splice(0, 1);

    if (resourceType === "journal") {
      publicationType = "1";
    } else if (resourceType === "paper_journal") {
      publicationType = "3";
    } else if (resourceType === "ebook") {
      publicationType = "2";
    }

    if (sort === "hot") {
      order = "desc";
    }

    let searchForm = {
      publicationType,
      pageNo,
      limit,
      searchField,
      searchInput: searchInput.map(
        Function.prototype.call,
        String.prototype.trim
      ),
      op,
      showType,
      filterField,
      filterValue,
      sort,
      order,
      subject,
      college,
      unit,
      resourceType,
      pageType,
      classSn,
      par,
      phonetic,
      strokes,
      language,
      alphabet,
      dbID,
      searchTarget,
      sc,
      domain,
      exhibitionID,
    };

    Object.keys(searchForm).map((key) => {
      if (
        searchForm[key] === null ||
        (typeof searchForm[key] === "string" && searchForm[key].trim() === "")
      ) {
        delete searchForm[key];
      }
      return "";
    });

    let state = {
      searchForm: searchForm,
      auth: readerStore.auth,
      resourceType: resourceType,
      pageType: pageType,
    };

    return state;
  }

  @autobind
  resetPage() {
    let { search, location } = this.props;
    let params = { ...search };
    delete params["pageNo"];
    delete params["limit"];
    let path =
      Object.keys(params).length > 0
        ? [location.pathname, qs.stringify(params)].join("?")
        : location.pathname;
    navigate(path);
  }

  @autobind
  resourceListComp() {
    if (this.state.searchForm !== undefined) {
      switch (this.state.resourceType) {
        case "journal":
          return (
            <JournalResourceListComp
              searchForm={this.state.searchForm}
              resourceType={this.state.resourceType}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "paper_journal":
          return (
            <JournalResourceListComp
              searchForm={this.state.searchForm}
              resourceType={this.state.resourceType}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "ebook":
          return (
            <JournalResourceListComp
              searchForm={this.state.searchForm}
              resourceType={this.state.resourceType}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        default:
      }
    }
  }

  render() {
    let searchBlockType = "resource";
    if (
      this.state.searchForm.exhibitionID === undefined ||
      this.state.searchForm.exhibitionID === ""
    ) {
      navigate("/");
    }
    return (
      <Layout {...this.props}>
        <div className="main lp" id="center">
          <ExhibitionComp searchForm={this.state.searchForm} {...this.props} />
          <ResourceListTab
            resourceType={this.state.resourceType}
            searchType={searchBlockType}
            searchForm={this.state.searchForm}
            {...this.props}
          />
          <section className="main_content erm">
            <div className="container">{this.resourceListComp()}</div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default ExhibitionResourceList;
